import common from 'json-loader!yaml-loader!@locales/common.yaml'
import { locales } from '@/constants/locale'

export default {
  install(Vue) {
    Vue.mixin({
      created() {
        // title description
        if (this.$route && this.$route.params.lang && !document.title) {
          document.title = common[this.$route.params.lang]['common005']
          document.querySelector('meta[name="description"]').content = common[this.$route.params.lang]['common006']
          document.querySelector('meta[property="og:site_name"]').content = common[this.$route.params.lang]['common005']
          document.querySelector('meta[property="og:title"]').content = common[this.$route.params.lang]['common005']
          document.querySelector('meta[property="og:description"]').content = common[this.$route.params.lang]['common006']
          document.querySelector('meta[property="twitter:title"]').content = common[this.$route.params.lang]['common005']
          document.querySelector('meta[property="twitter:description"]').content = common[this.$route.params.lang]['common006']
        }
        // hreflang
        if (document.querySelectorAll('link[rel="alternate"]').length < 1) {
          const localeList = locales.enums.map(l => {
            let _l =  l.key.split('_')
            return _l.join('-')
          })
          for (const i of localeList) {
            let path = location.pathname.split('/').filter(p => p !== '')
            path.splice(0, 1, i.toLowerCase())
            let _i = i.split('-')
            _i.splice(0, 1, _i[0].toLowerCase())
            const link = document.createElement('link')
            link.rel = 'alternate'
            link.hreflang = _i.join('-')
            link.href = `${location.origin}/${path.join('/')}`
            document.getElementsByTagName('head')[0].appendChild(link)
          }
        } else {
          let path = location.pathname.split('/')
          path.splice(0, 2)
          const pathRegExp = new RegExp(path.join('\\/')+'$')
          const links = document.querySelectorAll('link[rel="alternate"]')
          if (!pathRegExp.test(links[0].href)) {
            for (const i of links) {
              i.href = `${location.origin}/${i.hreflang.toLowerCase()}/${path.join('/')}`
            }
          }
        }
      },
    })
    Vue.prototype.$meta = function (meta) {
      document.title = meta.title
      document.querySelector('meta[name="description"]').content = meta.description
      document.querySelector('meta[property="og:site_name"]').content = meta.title
      document.querySelector('meta[property="og:title"]').content = meta.title
      document.querySelector('meta[property="og:description"]').content = meta.description
      document.querySelector('meta[property="twitter:title"]').content = meta.title
      document.querySelector('meta[property="twitter:description"]').content = meta.description
    }
  }
}