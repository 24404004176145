<template>
  <header
    :class="`-${this.locale}`"
    @touchmove="handleTouchMove"
    class="main-header"
  >
    <animate-button
      v-if="isMypage"
      @click="goToTop()"
      class="mypage-button"
    >
      <img
        :src="require(`@images/the-header/${locale}/btn_top.png`)"
        :alt="$t('top035')"
        class="text"
        width="162"
        height="162"
      >
    </animate-button>
    <animate-button
      v-else
      @click="goToMypage()"
      class="mypage-button"
    >
      <img
        :src="require(`@images/the-header/${locale}/btn_mypage.png`)"
        :alt="$t('top035')"
        class="text"
        width="162"
        height="162"
      >
    </animate-button>
    <language-select v-show="isLanguageSelectShow" />
    <h1
      v-if="isTitleShow"
      v-html="currentTheme.name"
      class="title"
    />
    <animate-button
      v-show="isSoundButtonShow"
      @click="toggleBgm"
      class="sound-button"
    >
      <img
        :src="soundImageSrc"
        alt="sound"
        class="image"
      >
    </animate-button>
  </header>
</template>

<i18n lang="yaml" src="@locales/top.yaml"></i18n>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
import VisibilityChangeManager from '@libraries/VisibilityChangeManager'
import { isIE } from '@libraries/util'
import LanguageSelect from '@components/global/language-select.vue'
import ModalModel from '@models/modal'
import NaModal from '@components/modals/na-modal.vue'
import AnimateButton from '@components/global/button-wrapper'

export default {
  components: {
    LanguageSelect,
    AnimateButton
  },

  data () {
    return {
      defaultSeriesId: 101
    }
  },

  computed: {
    ...mapGetters('login', ['isLogin']),
    ...mapGetters('bgm', ['isPlaying', 'currentBgm', 'bgmId']),
    ...mapGetters('theme', ['currentTheme']),

    locale () {
      return this.$route.params.lang ? this.$route.params.lang : 'ja-jp'
    },

    preventTouchMove () {
      return this.$route.name === 'HeroList'
    },

    isMypage () {
      return this.$route.name === 'Mypage'
    },

    canPlayBgm () {
      return /\/series/.test(this.$route.path)
    },

    isLanguageSelectShow () {
      const showPages = ['Top', 'Mypage', 'Result', 'ResultList', 'Error', 'Error404']
      return showPages.some(p => p === this.$route.name)
    },

    isSoundButtonShow () {
      if (isIE) return false
      const showPages = ['Series']
      return showPages.some(p => p === this.$route.name)
    },

    isTitleShow () {
      const showPages = ['Series', 'HeroList', 'Hero', 'Voted']
      return showPages.some(p => p === this.$route.name)
    },

    soundImageSrc () {
      const postfix = this.isPlaying ? 'off' : 'on'
      return require(`@images/the-header/btn_sound_${postfix}.png`)
    },

    nextBgmId () {
      if (!this.canPlayBgm) {
        return null
      }
      let seriesId

      if (this.$route.name === 'Series') {
        seriesId = +this.$route.query.series || this.defaultSeriesId
      } else {
        seriesId = +this.$route.params.seriesId
      }

      return seriesId
    },

    audioSrc () {
      if (!this.canPlayBgm) {
        return null
      }

      let path = null
      const exceptionSeries = [113, 115, 116]
      const bgmId = this.nextBgmId

      if (exceptionSeries.includes(bgmId)){
        const locale = this.locale === 'ja-jp' || this.locale === 'zh-tw' ? 'jp' : 'en'
        path = require(`@/assets/sounds/bgm_${bgmId}_${locale}.m4a`)
      } else {
        path = require(`@/assets/sounds/bgm_${bgmId}.m4a`)
      }

      return path
    },
  },

  watch: {
    '$route' () {
      // 再生中でなければ無視
      if (!this.isPlaying) {
        return
      }

      // 再生可能ページ
      if (this.canPlayBgm) {
        // 同じ曲IDではない
        if (this.nextBgmId !== this.bgmId) {
          this.playBgm()
        }
      } else {
        this.stopBgm()
      }

    }
  },

  created () {
    VisibilityChangeManager.addFunctionOnVisible(this.handleVisible)
    VisibilityChangeManager.addFunctionOnHidden(this.handleInvisible)
  },

  destroyed () {
    VisibilityChangeManager.removeFunctionsOnVisible(this.handleVisible)
    VisibilityChangeManager.removeFunctionsOnInvisible(this.handleInvisible)
  },

  methods: {
    ...mapActions('modal', { showModal: 'show' }),
    ...mapMutations('bgm', ['setIsPlaying', 'setCurrentBgm', 'setBgmId']),

    toggleBgm () {
      this.isPlaying ? this.stopBgm() : this.playBgm()
    },

    playBgm () {
      this.setupBgm()
      this.setIsPlaying(true)
      this.setBgmId(this.nextBgmId)
      this.currentBgm.load()
    },

    setupBgm () {
      this.currentBgm.removeEventListener('canplaythrough', this.handleCanPlayThrough)
      this.currentBgm.addEventListener('canplaythrough', this.handleCanPlayThrough)
      this.currentBgm.volume = 1
      this.currentBgm.loop = true
      this.currentBgm.preload = 'none'

      // aacのMIMEタイプは環境によって不統一
      // 一致しないとIEでは再生されない
      if (isIE) {
        this.currentBgm.type = 'audio/mpeg'// mpeg, mp4, x-m4a, x-mp4
      }
      
      this.currentBgm.src = this.audioSrc
    },

    stopBgm () {
      if (!this.currentBgm) {
        return
      }

      this.currentBgm.removeEventListener('canplaythrough', this.handleCanPlayThrough)
      this.currentBgm.pause()
      this.setIsPlaying(false)
    },

    resumeBgm () {
      if (!this.isPlaying) {
        return
      }
      this.currentBgm.play()
    },

    pauseBgm () {
      if (!this.isPlaying) {
        return
      }
      this.currentBgm.pause()
    },

    goToTop () {
      location.href = `/${this.locale}`
    },

    goToMypage () {
      if (!this.isLogin) {
        this.showModal(new ModalModel({
          component: NaModal,
          props: {
            type: 'mypage'
          }
        }))
        return
      }

      location.href = `/${this.locale}/mypage`
      // this.$router.push({ name: 'Mypage' })
    },

    handleCanPlayThrough () {
      this.currentBgm.play()
    },

    handleTouchMove (ev) {
      if (!this.preventTouchMove) {
        return
      }
      ev.preventDefault()
    },

    handleVisible () {
      this.resumeBgm()
      // muteの場合、再度ボタン操作がないと再開されない
      // this.currentBgm.muted = false
    },

    handleInvisible () {
      this.pauseBgm()
    },
  }
}
</script>

<style lang="scss" scoped>
.main-header {
  background: url(#{$img-path}the-header/bg.png) no-repeat center top;
  height: 118px;
  padding-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  touch-action: none;

  > .mypage-button {
    margin: 15px -15px 0 -5px;
  }

  > .title {
    background-color: rgba(0, 0, 0, .7);
    flex: 1;
    display: flex;
    height: 70px;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
    margin-right: 30px;
    overflow: hidden;
    color: $color-sub;
    font-weight: 600;
    border-radius: 10px;
    font-size: $fs-large;
    letter-spacing: -1px;
    text-align: center;
    line-height: 1.1;
    transform: translateZ(0);
  }

  > .sound-button {
    margin: 0 5px 0 -10px;
    flex: 0 0 106px;
  }

  &:not(.-ja-jp) {
    > .title {
      font-size: $fs-small;
    }
  }
  &.-zh-tw {
    > .title {
      font-size: $fs-large;
    }
  }
}
</style>
