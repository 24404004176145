import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { validateLocale } from '@/constants/locale'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:lang',
    component: {
      template: '<router-view />'
    },
    beforeEnter: (to, from, next) => {
      if (validateLocale(to.params.lang)) {
        next()
      } else {
        next('/')
      }
    },
    children: [
      {
        path: '', //1-1,2-3 TOPページ
        name: 'Top',
        component: () => import(/* webpackChunkName: "top" */ '../views/top.vue'),
        props: (route) => ({
          locale: route.params.lang
        })
      },
      {
        path: 'theme/:themeType',
        component: {
          template: '<router-view />'
        },
        beforeEnter: (to, from, next) => {
          // NOTE: 公開中のTheme以外はTOPに戻す 
          if (+to.params.themeType === +store.getters['theme/currentTheme'].id) {
            next()
          } else {
            next({ path: `/${to.params.lang}`})
          }
        },
        children: [
          {
            path: 'series', // 3-1 シリーズ選択ページ
            name: 'Series',
            component: () => import(/* webpackChunkName: "series" */ '../views/series.vue'),
            props: (route) => ({
              locale: route.params.lang
            })
          },
          {
            path: 'series/:seriesId', // 4-1 英雄一覧ページ
            name: 'HeroList',
            component: () => import(/* webpackChunkName: "hero-list" */ '../views/hero-list.vue'),
            props: (route) => ({
              locale: route.params.lang,
              themeType: +route.params.themeType,
              seriesId: +route.params.seriesId
            })
          },
          {
            path: 'series/:seriesId/:heroId', // 5-1 英雄投票ページ, 8-1 投票済みページ
            name: 'Hero',
            component: () => import(/* webpackChunkName: "hero" */ '../views/hero.vue'),
            props: (route) => ({
              locale: route.params.lang,
              themeType: +route.params.themeType,
              seriesId: +route.params.seriesId,
              heroId: +route.params.heroId,
            })
          },
          {
            path: 'series/:seriesId/:heroId/voted', // 7-1,2 投票完了ページ
            name: 'Voted',
            component: () => import(/* webpackChunkName: "hero" */ '../views/voted.vue'),
            props: (route) => ({
              locale: route.params.lang,
              themeType: +route.params.themeType,
              seriesId: +route.params.seriesId,
              heroId: +route.params.heroId,
            }),
            // 最後に投票したidと本ページのheroIdが一致するなら表示
            beforeEnter (to, from, next) {
              const votedId = store.getters['vote/getId']
              const heroId = +to.params.heroId

              if (heroId !== undefined && votedId === heroId) {
                next()
              } else {
                next({ path: `/${to.params.lang}`})
              }
            },
          },
        ]
      },
      {
        path: 'mypage', // 9-1 マイページ
        name: 'Mypage',
        component: () => import(/* webpackChunkName: "mypage" */ '../views/mypage.vue'),
        props: (route) => ({
          locale: route.params.lang
        }),
        beforeEnter (to, from, next) {
          if (store.getters['login/isLogin']) {
            next()
          } else {
            next({ path: `/${to.params.lang}`})
          }
        }
      },
      {
        path: 'result', // 10-1 結果発表ページ
        name: 'Result',
        component: () => import(/* webpackChunkName: "result" */ '../views/result.vue'),
        props: (route) => ({
          locale: route.params.lang
        })
      },
      {
        path: 'result/:themeType', // 11-1 結果発表ページ 1-100位
        name: 'ResultList',
        component: () => import(/* webpackChunkName: "result-list" */ '../views/result-list.vue'),
        props: (route) => ({
          locale: route.params.lang,
          themeType: +route.params.themeType,
        })
      },
      {
        path: 'error', //14-1 エラーページ
        name: 'Error',
        component: () => import(/* webpackChunkName: "err" */ '../views/error.vue'),
        props: (route) => ({
          locale: route.params.lang
        })
      },
      {
        path: 'debuglink', //debugページ
        name: 'DebugLink',
        component: () => import(/* webpackChunkName: "err" */ '../views/debug.vue'),
        props: (route) => ({
          locale: route.params.lang
        }),
        beforeEnter: (to, from, next) => {
          if (process.env.NODE_ENV === 'production') {
            next(`/${to.params.lang}`)
          } else {
            next()
          }
        }
      },
      {
        path: '*', //14-1 エラーページ
        name: 'Error404',
        component: () => import(/* webpackChunkName: "err" */ '../views/error.vue'),
        props: (route) => ({
          locale: route.params.lang
        })
      }
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
})

router.beforeEach(async (to, from, next) => {
  // @NOTE エラーページからの遷移は全て一度データを読み直す
  if (from.name === 'Error') {
    location.href = to.path
    return
  }

  // 同一ページでなければローダーを表示
  if (to.name !== from.name) {
    await store.dispatch('loader/show')
    store.dispatch('modal/hide')
    store.dispatch('terms/hide')
    window.scrollTo(0, 0)
  }

  next()
})

router.afterEach(() => {
  // store.dispatch('loader/hide')
})

export default router
