export default class Result {
  constructor (json) {
    try {
      this.map(json)
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
    }
  }

  map (json) {
    this.themeId = typeof json.theme_id === 'number' ? json.theme_id : null
    this.results = json.results || []
  }

  toJSON() {
    return {
      model_themeId: this.themeId,
      model_results: this.results,
    }
  }
}
