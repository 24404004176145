import { getServerParam } from '@constants/wallpapers'

export default {
  namespaced: true,

  state: {
    data: null
  },

  getters: {
    get (state) {
      return state.data
    },
  },

  mutations: {
    set (state, data) {
      state.data = data
    }
  },

  actions: {
    init ({ commit }) {
      const useDummyData = process.env.VUE_APP_USE_DUMMY ? process.env.VUE_APP_USE_DUMMY.includes('wallpapers') : false || false
      let data = getServerParam(useDummyData)
      if (data) {
        commit('set', data.items)
      }
    },
  }

}