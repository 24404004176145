export default {
  namespaced: true,

  state: {
    bgmId: null,
    isPlaying: false,
    currentBgm: new Audio(),
  },

  getters: {
    bgmId (state) {
      return state.bgmId
    },

    isPlaying (state) {
      return state.isPlaying
    },

    currentBgm (state) {
      return state.currentBgm
    },
  },

  mutations: {
    setBgmId (state, bool) {
      state.bgmId = bool
    },

    setIsPlaying (state, bool) {
      state.isPlaying = bool
    },

    setCurrentBgm (state, bgm) {
      state.currentBgm = bgm
    },
  }
}