<template>
  <footer
    v-if="isShow"
    class="main-footer"
  >
    <div
      :class="{ '-terms': isTermsShow }"
      class="inner"
    >
      <a
        v-if="isTermsShow"
        v-html="$t('top036')"
        @click.prevent="showTerms()"
        href="#"
        class="terms"
      />
      <p class="copyright">
        <small v-html="$t('top047')"></small>
      </p>
    </div>
  </footer>
</template>

<i18n lang="yaml" src="@locales/top.yaml"></i18n>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TheFooter',

  data () {
    return {
      pagesWithNoTerms: [ 'Hero' ]
    }
  },

  components: {
  },

  computed: {
    ...mapGetters('loader', { isFirstLoad: 'isFirst' }),

    isShow () {
      return this.$route.name !== 'HeroList' && !this.isFirstLoad
    },

    isTermsShow () {
      return this.pagesWithNoTerms.some(pageName => pageName !== this.$route.name)
    }
  },

  methods: {
    ...mapActions('terms', { showTerms: 'show' })
  }
}
</script>

<style lang="scss" scoped>
.main-footer {
  display: flex;
  align-items: center;
  justify-content: center;

  > .inner {
    position: relative;
    background: #0d151a url(#{$img-path}the-footer/bg.png) no-repeat center bottom;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center; 
    flex-direction: column;

    &.-terms {
      height: 140px;
    }

    &:before {
      content: '';
      position: absolute;
      top: -10px;
      left: 0;
      right: 0;
      width: 100%;
      height: 10px;
      background: url(#{$img-path}the-footer/shadow.png) repeat-x;
    }

    &:after {
      content: '';
      position: absolute;
      top: 0px;
      left: 0;
      right: 0;
      width: 100%;
      height: 2px;
      background: linear-gradient(90deg, rgb(13,21,26) 0%, rgb(138,207,211) 50%, rgb(13,21,26) 100%) repeat-x;
    }

    > .terms {
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-sub;
      font-size: $fs-xsmall;
      text-shadow: 0 3px 4px rgba(#000, 0.6);
      margin-bottom: -3px;
      line-height: 1.2;
      min-height: $fs-xsmall * 2;
      margin-left: 40px;
      margin-right: 40px;

      &:before {
        content: '';
        display: block;
        background: url(#{$img-path}the-footer/icon_arrow.png) no-repeat;
        width: 46px;
        height: 32px;
        margin-top: 3px;
        margin-right: 18px;
        flex: 0 0 46px;
      }
    }

    > .copyright {
      color: $color-sub;
      text-shadow: 0 3px 4px rgba(#000, 0.6);
      font-size: $fs-base;
      line-height: lh(46, $fs-base);
    }
  }
}
</style>