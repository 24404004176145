<template>
  <transition
    name="loader"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
    @after-leave="afterLeave"
    appear
  >
    <div
      v-if="isShow"
      @touchmove.prevent
      class="loader"
    >
      <div class="loader-content">
        <div class="main"></div>
      </div>
    </div>
  </transition>
</template>

<script>
import { gsap } from 'gsap'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'TheLoader',

  data () {
    return {
      tls: {},
      // duration: 0.4
    }
  },

  computed: {
    ...mapGetters('loader', [ 'config', 'isShow', 'isFirst' ])
  },

  mounted () {
  },

  destroyed () {
    Object.values(this.tls).map(tl => tl.kill())
  },

  methods: {
    ...mapMutations('loader', [ 'setIsShowing', 'setIsFirst' ]),

    beforeEnter () {
      this.setIsShowing(true)
    },

    enter (el, done) {
      if (this.isFirst || this.isShowing) {
        done()
        return
      }

      this.tls.enter = gsap.timeline({
        onComplete: () => {
          done()
        }
      })
      
      this.tls.enter
        .fromTo(el, {
          opacity: 0
        }, {
          opacity: 1,
          duration: this.config.duration,
          ease: 'quad.out'
        })
    },

    afterEnter () {
    },

    beforeLeave () {

    },

    leave (el, done) {
      this.tls.leave = gsap.timeline({
        onComplete: () => {
          done()
        }
      })

      this.tls.leave
        .to(el, {
          opacity: 0,
          duration: this.config.duration,
          ease: 'quad.out'
        })
    },

    afterLeave () {
      this.setIsShowing(false)
      this.setIsFirst(false)
    }
  }
}
</script>

<style lang="scss" scoped>
.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  min-height: 100%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-sub;
  backface-visibility: hidden;
  z-index: map-get($z-index, loader);

  .loader-content {
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(#{$img-path}loading/bg_loading.png) no-repeat center center;
    width: 500px;

    > .main {
      background: url(#{$img-path}loading/loading.png) no-repeat;
      width: 140px;
      height: 158px;
      animation: spin 1.8s linear infinite;
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(1turn);
    }
  }
}
</style>