export default class User {
  constructor (obj) {
    try {
      this.map(obj)
    } catch (e) {
      console.warn('Invalid Type: ModelUser')
    }
  }
  map (obj) {
    this.userId = obj.na_user_id || null
    this.nickname = obj.nick_name || null 
    this.imageUrl = obj.mii_image_url || null
  }
}