import Vue from 'vue'
import App from './app.vue'
import router from './router'
import store from './store'
import IScrollView from 'vue-iscroll-view'
import IScroll from 'iscroll/build/iscroll-probe'
import i18n from './i18n'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import VueScrollTo from 'vue-scrollto'
import './assets/scss/main.scss'
import meta from './plugins/meta'

Vue.config.productionTip = false

Vue.use(IScrollView, IScroll)
Vue.use(VueAwesomeSwiper)
Vue.use(VueScrollTo, {
  container: 'body',
  duration: 800,
  easing: [0.645, 0.045, 0.355, 1],
  offset: 0,
  force: true,
  cancelable: false,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})
Vue.use(meta)

Vue.prototype.$reload = function() {
  this.$router.go({ path: this.$router.currentRoute.path, force: true })
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
