<template>
  <transition
    name="modal"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
    @after-leave="afterLeave"
    :css="false"
    appear
  >
    <section
      v-show="isShow"
      @touchmove.prevent
      @mousedown="handleMouseDown"
      class="base-modal"
    >
      <iscroll-view
        :options="iScrollView.options"
        :scrollerClass="{ scroller: true }"
        ref="iscroll"
        class="modal-scroller"
      >
        <div class="modal-inner">
          <!-- @NOTE 動的コンポーネントのrefが拾えない -->
          <component
            v-if="modal"
            :is="modal.component"
            :class="{ '-locked':  isLock }"
            class="modal-content"
          />
          <modal-closer @click="hide" />
        </div>
      </iscroll-view>
    </section>
  </transition>
</template>

<script>
import { gsap } from 'gsap'
import { mapState, mapGetters, mapActions } from 'vuex'
import ModalCloser from '@/components/modals/modal-closer.vue'

export default {
  name: 'Modal',

  components: {
    ModalCloser
  },

  data () {
    return {
      content: null,
      duration: 0.2,
      tls: {},
      isLock: true,
      $iscroll: null,
      iScrollView: {
        options: {
          scrollbars: true,
          shrinkScrollbars: 'scale',
          mouseWheel: true,
          click: true,
          tap: true
        }
      }
    }
  },

  computed: {
    ...mapState('modal', ['modal']),
    ...mapGetters('modal', ['isShow']),
  },

  watch: {
    isShow (val) {
      val ? this.refreshIScroll() : this.resetIScroll()
    }
  },

  mounted () {
    this.$iscroll = this.$refs.iscroll
    this.refreshIScroll()
  },

  destroyed () {
  },

  methods: {
    ...mapActions('modal', { _hide: 'hide', reset: 'reset' }),

    hide () {
      if (this.isLock) {
        return
      }
      this._hide()
    },

    beforeEnter () {
      this.isLock = true
    },

    enter (el, done) {
      this.content = el.querySelector('.modal-content')
      this.tls.enter = gsap.timeline({
        onComplete: () => {
          done()
        }
      })

      this.tls.enter
        .add([
          gsap.fromTo(el, {
            opacity: 0
          }, {
            opacity: 1,
            duration: this.duration,
            ease: 'quad.out'
          }),
          gsap.fromTo(this.content, {
            scale: 0.5
          }, {
            scale: 1,
            delay: -this.duration * 0.5,
            duration: this.duration,
            ease: 'cubic.in'
          })
        ])
    },

    afterEnter () {
      this.isLock = false
    },

    beforeLeave () {
      this.isLock = true
    },

    leave (el, done) {
      this.tls.leave = gsap.timeline({
        onComplete: () => {
          done()
        }
      })

      this.tls.leave
        .add([
          gsap.fromTo(el, {
            opacity: 1
          }, {
            opacity: 0,
            duration: 0.1,
            ease: 'quad.out'
          }),
          gsap.fromTo(this.content, {
            scale: 1
          }, {
            scale: 0.5,
            duration: 0.1,
            ease: 'cubic.out'
          })
        ])
    },

    afterLeave () {
      this.isLock = false
      this.reset()
    },

    refreshIScroll () {
      if (!this.$iscroll) {
        return
      }

      setTimeout(() => this.$iscroll.refresh(), 0)
    },

    resetIScroll () {
      this.$iscroll.scrollTo(0, 0, 0);
    },

    handleMouseDown (ev) {
      if (ev.button !== 1) {
        return
      }
      ev.preventDefault()
      ev.stopPropagation()
    }
  }
}
</script>

<style lang="scss" scoped>
.base-modal {
  background: rgba(#000, 0.7);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: map-get($z-index, modal); 
  backface-visibility: hidden;
}
  .modal-scroller {
    touch-action: none;// for chrome bug
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 100%;

    > div {
      // NOTE: IEで位置がおかしくなる
      width: 722px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;

      position: absolute;
      top: 0;
      padding-top: 51px;
      padding-bottom: 51px;
    }
  }

  .modal-inner {
    position: relative;

    > .modal-content {
      position: relative;

      &.-locked {
        pointer-events: none;
      }
    }
    > .modal-closer {
      position: absolute;
      top: -16px;
      right: -18px;
      z-index: 100;
    }
  }
</style>
