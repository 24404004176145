<template>
<div
  :class="classes"
  class="na-modal"
>
  <div class="modal-inner">
    <h2 class="title">
      <img
        src="@images/na/title.png"
        alt="Nitendo Account"
        width="180"
        height="180"
      >
    </h2>
    <div class="texts-container">
      <div class="inner">
        <p
          v-if="isMypage"
          class="text"
          v-html="$t('modal008')"
        />
        <p class="text" v-html="$t('modal001')"></p>
        <ul class="condition-list">
          <li class="item" v-html="$t('modal003')" />
          <li class="item" v-html="$t('modal002')" />
          <li class="item" v-html="$t('modal004')" />
        </ul>
      </div>
    </div>
    <div class="action-buttons">
      <action-button
        tag="a"
        :href="`/login?url=${redirectUrl}`"
        class="item"
      >{{ isMypage ? $t('modal009') : $t('modal005') }}</action-button>
      <a
        v-html="$t('modal006')"
        href="https://my.nintendo.com/getting_started"
        target="_blank"
        class="arrow-link"
      />
      <action-button
        tag="a"
        @click="goWithoutLogin"
        class="item"
      >{{ isMypage ? $t('modal010') : $t('modal007') }}</action-button>
    </div>
  </div>
</div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import ActionButton from '@/components/global/button.vue'

export default {
  name: 'NaModal',

  components: {
    ActionButton
  },

  computed: {
    ...mapState('modal', ['modal']),

    locale () {
      return this.$route.params.lang
    },

    redirectUrl () {
      return this.isDefault ?
        this.modal.props.redirectTo :
        `${location.origin}/${this.$route.params.lang}/mypage`
    },

    isDefault () {
      return this.modal.props.type == null || this.modal.props.type === 'default'
    },

    isMypage () {
      return this.modal.props.type === 'mypage'
    },

    classes () {
      return { '-mypage' : this.isMypage }
    }
  },

  methods: {
    ...mapActions('modal', {
      showModal: 'show',
      hideModal: 'hide'
    }),

    goWithoutLogin () {
      if (this.isMypage) {
        this.hideModal()
      } else {
        if(this.modal.props.isModal) {
          this.showModal(this.modal.props.withoutLogin)
        } else {
          this.$router.push(this.modal.props.withoutLogin)
          this.hideModal()
        }
      }
    },
  }
}
</script>

<i18n lang="yaml" src="@/locales/modal.yaml"></i18n>

<style lang="scss" scoped>
.na-modal {
  width: 722px;
  min-height: 1016px;
  /*
  border-style: solid;
  border-width: 52px 80px 52px 80px;
  border-image: url(#{$img-path}box/bluebox_bg.png) 52 80 52 80 fill stretch;
  */
  &:before,
  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 52px;
  }
  &:before {
    background: url(#{$img-path}box/bluebox_bg_top.png) no-repeat center top;
  }
  &:after {
    background: url(#{$img-path}box/bluebox_bg_bottom.png) no-repeat center bottom;
  }

  > .modal-inner {
    background: url(#{$img-path}box/bluebox_bg_repeat.png) repeat-y center top;
    padding-top: 17px;
    padding-bottom: 12px;

    > .title {
      display: flex;
      justify-content: center;
    }

    > .texts-container {
      // IE用のwrapper
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
    }
      > .texts-container .inner {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 360px;
        padding-top: 16px;
        padding-bottom: 16px;
      }
      > .texts-container .text {
        color: $color-sub;
        font-size: $fs-large;
        font-weight: 600;
        text-shadow: $ts-base;
        text-align: center;
        letter-spacing: 0.02em;
        line-height: lh(42, $fs-large);
        padding-left: 40px;
        padding-right: 40px;
      }
      > .texts-container .text + .text {
        margin-top: 14px;
      }
      > .texts-container .condition-list {
        margin-top: 10px;
        padding-left: 78px + 5px;
        padding-right: 36px;
      }

    > .action-buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
  .arrow-link {
    font-size: $fs-middle;
    color: $color-sub;
    text-shadow: $ts-base;
    background: url(#{$img-path}na/icon_arrow.png) no-repeat left center;
    padding-left: 38px + 15px;
  }

  .condition-list {
    > .item {
      $top: strip-unit($fs-middle) * 5px;
      color: $color-sub;
      font-size: $fs-middle;
      text-shadow: $ts-base;
      letter-spacing: 0.02em;
      line-height: lh(48, $fs-middle);
      background: url(#{$img-path}na/icon_bullet.png) no-repeat left $top;
      padding-left: 42px;
    }
  }
  .action-buttons {
    > .item {
      width: 600px;
      margin-top: 45px;
    }
    > .item:first-child {
      margin-top: 0;
    }
  }
</style>