<template>
  <form class="language-select">
    <select
      class="select"
      v-model="selectLang"
    >
      <option
        v-for="(item, i) in localeList"
        :key="i"
        :value="item.value.name"
        :selected="isSelected(item.value.name)"
      >
        {{ item.value.label }}
      </option>
    </select>
    <div class="arrow"></div>
  </form>
</template>

<script>
import { locales } from '@/constants/locale'

export default {
  data() {
    return {
      selectLang: null,
    }
  },
  computed: {
    localeList () {
      return locales.enums.map(l => l)
    }
  },
  watch: {
    '$route.params.lang' (value) {
      this.selectLang = value
    },
    'selectLang' (value) {
      const params = this.$route.params
      params.lang = value
      this.$router.replace({ name: this.$route.name, query: this.$route.query, params }).catch(() => {})
    }
  },
  methods: {
    isSelected (lang) {
      return lang === this.$route.params.lang
    }
  }
}
</script>

<style lang="scss" scoped>
.language-select {
  width: 478px;
  display: block;
  border: 2px solid #69512e;
  position: relative;
  margin: 0 24px;
  $self: #{&};

  @at-root {
    .-pc {
      #{$self} {
        @include tap-event();
      }
    }
  }

  > .select {
    border: 0;
    margin: 0;
    outline: none;
    border-radius: 0;
    background: linear-gradient(180deg, rgb(208,199,145) 0%, rgb(233,229,197) 100%);
    box-shadow: inset 0px 3px 2px 0px rgba(52, 50, 37, 0.35);
    height: 56px;
    font-size: $fs-xlarge;
    padding: 3px 20px;
    width: 100%;
    display: block;
    appearance: none;
    cursor: pointer;
  }

  > .arrow {
    position: absolute;
    top: -2px;
    right: -2px;
    width: 60px;
    height: 60px;
    background-color: #69512e;
    pointer-events: none;
    display: flex;
    align-items: center;
    justify-content: center;

    &::before {
      content: "";
      display: block;
      background: url(#{$img-path}the-header/language_icon_arrow.png) no-repeat;
      width: 24px;
      height: 24px;
    }
  }
}

</style>
