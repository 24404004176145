const ua = window.navigator.userAgent

export async function sleep (s, func) {
  func = func || function () {}
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(func)
    }, s * 1000)
  })
}

export const isIE = ua.match(/(msie|MSIE)/) || ua.match(/(T|t)rident/)
export const isEdge = ua.indexOf('Edge') !== -1

