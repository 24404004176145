import { getServerParam } from '@constants/time'

export default {
  namespaced: true,

  state: {
    time: null,
    schedule: [
      { id: 'top_visual', time: '2020-04-11T12:00:00+09:00' }
    ],
    openList: null
  },

  getters: {
    get (state) {
      return state.time
    },

    checkOpen: (state) => (id) => {
      const target = state.openList.find(item => item.id === id)
      return target === undefined ? false : target.isOpen
    }
  },

  mutations: {
    set (state, time) {
      state.time = new Date(time)
      state.openList = Object.values(state.schedule).map((item) => {
        return {
          id: item.id,
          isOpen: +state.time >= +new Date(item.time)
        }
      })
    }
  },

  actions: {
    init({ commit }) {
      const useDummyData = process.env.VUE_APP_USE_DUMMY ? process.env.VUE_APP_USE_DUMMY.includes('time') : false || false
      const time = getServerParam(useDummyData)
      commit('set', time.time)
    },
  }
}
