export default {
  namespaced: true,

  state: {
    isShow: false
  },

  mutations: {
    setIsShow (state, value) {
      state.isShow = value
    },
  },

  actions: {
    show ({ commit }) {
      commit('setIsShow', true)
    },

    hide ({ commit }) {
      commit('setIsShow', false)
    }
  }
}
