import { render, staticRenderFns } from "./the-footer.vue?vue&type=template&id=54ef5b06&scoped=true&"
import script from "./the-footer.vue?vue&type=script&lang=js&"
export * from "./the-footer.vue?vue&type=script&lang=js&"
import style0 from "./the-footer.vue?vue&type=style&index=0&id=54ef5b06&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54ef5b06",
  null
  
)

/* custom blocks */
import block0 from "@locales/top.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Fcircleci%2Fworkspace%2Ffrontend%2Fsrc%2Fcomponents%2Fthe-footer.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports