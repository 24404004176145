import { getLocalJson } from './local/index.js'

export const getServerParam = (useDummyData) => {
  let config = {}
  const element = document.getElementById('config')
  if (element && element.tagName.toLowerCase() == 'script' && !useDummyData) {
    try {
      config = JSON.parse(element.innerHTML)
    } catch {
      throw { error: 'JSON parse error' }
    }
  } else {
    config = getLocalJson()
  }
  // eslint-disable-next-line
  if (useDummyData) console.log('Refered Local Data Result', config.result)
  return config.result
}