import { gsap } from 'gsap'

export default {
  props: {
    buttonConfig: {
      type: Object,
      default: function () {
        return {
          defaultScale: 1,
          duration: 0.2,
          scaleCoefficient: 0.9,
          restitutionCoefficient: 1
        }
      }
    }
  },
  methods: {
    animateButton () {
      const config = this.buttonConfig
      gsap
        .from(this.$el, {
          scale: config.scaleCoefficient,
          ease: `back.out(${config.restitutionCoefficient})`,
          duration: config.duration,
          onStart: () => {
            gsap.set(this.$el, { scale: config.defaultScale })
          },
          onComplete: () => {
            gsap.set(this.$el, { scale: config.defaultScale })
          }
        })
    }
  }
}
