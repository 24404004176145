<template>
  <div
    :class="classes"
    id="app"
    class="lang-jp"
  >
    <div class="container">
      <main-header />
      <router-view class="main-body" />
      <main-footer />
    </div>
    <modal />
    <terms />
    <loader />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { validateLocale, getLocale } from '@/constants/locale'
import MainHeader from '@components/the-header.vue'
import MainFooter from '@components/the-footer.vue'
import Loader from '@components/the-loader.vue'
import Modal from '@components/modal.vue'
import Terms from '@components/modals/terms-modal'
import { isIE, isEdge } from '@libraries/util'
import isMobile from 'ismobilejs'

export default {
  components: {
    MainHeader,
    MainFooter,
    Loader,
    Modal,
    Terms,
  },

  data () {
    return {
      isMobile: isMobile()
    }
  },

  computed: {
    ...mapGetters('theme', [ 'currentTheme' ]),

    classes () {
      const classes = []
      const name = this.$route.name
      const lang = this.$route.params.lang

      if (name) {
        classes.push(`-${name.toLowerCase()}`)
      }
      if (lang) {
        classes.push(`-${lang}`)
      }
      if (isIE) {
        classes.push('-ie')
      } else if (isEdge) {
        classes.push('-edge')
      }
      
      classes.push(this.isMobile.any ? '-mobile' : '-pc')

      return classes
    },
  },

  // @FIXME language-selectと一部機能がバッティングしている?
  watch: {
    '$route.params.lang' (newVal, oldVal) {
      if (oldVal !== undefined && oldVal !== newVal) {
        if (isIE) {
          location.reload()
        } else {
          this.$reload()
        } 
        return
      }
      this.$i18n.locale = newVal
    }
  },

  created () {
    const locale = location.pathname.split('/')[1]

    if (!validateLocale(locale)) {
      location.href = `/${getLocale().value.name}`
      return
    }
    this.$i18n.locale = locale

    this.initTime()
    this.initLogin()
    this.initTheme()
    this.initResult()
    this.initVote(this.currentTheme)
    this.initWallpapers()
  },

  mounted () {
  },

  methods: {
    ...mapActions('time', { initTime: 'init' }),
    ...mapActions('login', { initLogin: 'init' }),
    ...mapActions('theme', { initTheme: 'init' }),
    ...mapActions('result', { initResult: 'init' }),
    ...mapActions('vote', { initVote: 'init' }),
    ...mapActions('wallpapers', { initWallpapers: 'init' }),
  }
}
</script>

<style lang="scss">
.lang-jp {
  font-family: $ff-lang-jp;
}
.container {
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.main-header {
  width: $base-w;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: map-get($z-index, header);
}
.main-body {
  position: relative;
  overflow: hidden;
  display: block;// fallback
  width: $base-w;
  margin-left: auto;
  margin-right: auto;
  min-height: 720px;
  padding-top: 110px;
  background:
    url(#{$img-path}common/bg_line_l.png) repeat-y left top,
    url(#{$img-path}common/bg_line_r.png) repeat-y right top,
    url(#{$img-path}common/bg_base.png) repeat-y center top;
  z-index: map-get($z-index, body);
}
  .page-footer {
    background: url(#{$img-path}the-footer/bg_main.jpg) repeat-y;
    position: relative;
    overflow: hidden;
    z-index: 20;

    > .sns-share {
      margin-bottom: 40px;
    }
    > .app-download {
      margin-top: 40px;
      margin-bottom: 36px;
    }
  }
.main-footer {
  width: $base-w;
  margin-left: auto;
  margin-right: auto;
  z-index: map-get($z-index, footer);
}
</style>
