import { sleep } from '@libraries/util/'

export default {
  namespaced: true,

  state: {
    config: {
      duration: 0.4,
      minDisplayDuration: 0.2
    },
    isFirst: true, // 初回表示判定
    isShow: false, // 表示有無
    isShowing: false // すでに表示中か否か
  },

  getters: {
    config (state) {
      return state.config
    },
    isFirst (state) {
      return state.isFirst
    },
    isShowing (state) {
      return state.isShowing
    },
    isShow (state) {
      return state.isShow
    }
  },

  mutations: {
    setConfig (state, value) {
      Object.assign(state.config, value)
    },
    setIsFirst (state, value) {
      state.isFirst = value
    },
    setIsShow (state, value) {
      state.isShow = value
    },
    setIsShowing (state, value) {
      state.isShowing = value
    }
  },

  actions: {
    async show ({ commit, state }) {
      commit('setIsShow', true)
      return sleep(state.config.duration)
    },
    async hide ({ commit, state }) {
      commit('setIsShow', false)
      return sleep(state.config.duration)
    }
  }
}
