import ModelTheme from '@models/theme'
import { getServerParam } from '@constants/theme'

export default {
  namespaced: true,

  state: {
    themes: null,
  },

  getters: {
    getThemes (state) {
      return state.themes
    },

    currentTheme (state) {
      const current = state.themes.filter(s => s.status === 1)
      return current.length > 0 ? current[0] : null
    }
  },

  mutations: {
    setThemes (state, themes) {
      state.themes = themes.map(t => new ModelTheme(t))
    }
  },

  actions: {
    init ({ commit }) {
      const useDummyData = process.env.VUE_APP_USE_DUMMY ? process.env.VUE_APP_USE_DUMMY.includes('theme') : false || false
      const themes = getServerParam(useDummyData)

      if (themes.items.length > 0) {
        commit('setThemes', themes.items)
      }
    },
  }
}
