<template>
  <component
    :is="tag"
    @click.prevent="handleClick"
    class="modal-closer"
  >
    <img :src="src" alt="close">
  </component>
</template>

<script>
import ButtonMixin from '@/mixins/Button'

export default {
  name: 'ModalCloser',

  props: {
    type: {
      type: String,
      default: null
    },
    tag: {
      type: String,
      default: 'button'
    }
  },

  mixins: [ ButtonMixin ],

  computed: {
    locale () {
      return this.$route.params.lang
    },
    
    src () {
      if (!this.locale) {
        return
      }

      return this.type === null ? 
        require(`@images/common/${this.locale}/btn_close.png`) :
        require(`@images/common/${this.locale}/btn_close${this.type}.png`)
    }
  },

  methods: {
    handleClick () {
      this.animateButton()
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-closer {
}
</style>