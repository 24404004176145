<template>
  <transition
    name="modal"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @before-leave="beforeLeave"
    @leave="leave"
    @after-leave="afterLeave"
    :css="false"
    appear
  >
    <section
      v-show="isShow"
      @touchmove.prevent
      @mousedown="handleMouseDown"
      id="terms"
      class="terms-modal"
    >
      <iscroll-view
        :options="iScrollView.options"
        :scrollerClass="{ scroller: true }"
        ref="iscroll"
        class="modal-scroller"
      >
        <div class="modal-inner">
          <div
            :class="{ '-locked':  isLock }"
            class="modal-content"
          >
            <h2 class="terms-title" v-html="$t('agreement001')" />
            <p class="terms-text" v-html="$t('agreement002')" />
          </div>
        </div>
      </iscroll-view>
      <modal-closer
        type="2"
        tag="a"
        @click="hide"
      />
    </section>
  </transition>
</template>

<script>
import { gsap } from 'gsap'
import { mapState, mapActions } from 'vuex'
import ModalCloser from '@/components/modals/modal-closer.vue'

export default {
  name: 'TermsModal',

  components: {
    ModalCloser
  },

  data () {
    return {
      content: null,
      duration: 0.2,
      enterTl: null,
      leaveTl: null,
      isLock: true,
      $iscroll: null,
      iScrollView: {
        options: {
          scrollbars: true,
          shrinkScrollbars: 'scale',
          mouseWheel: true,
          click: true,
          tap: true
        }
      }
    }
  },

  computed: {
    ...mapState('terms', ['isShow']),

    locale () {
      return this.$route.params.lang
    },
  },

  watch: {
    isShow (val) {
      val ? this.refreshIScroll() : this.resetIScroll()
    }
  },

  created () {
    window.addEventListener('resize', this.handleResize)
  },

  mounted () {
    this.$iscroll = this.$refs.iscroll
    this.refreshIScroll()
  },

  destroyed () {
    window.addEventListener('resize', this.handleResize)
  },

  methods: {
    ...mapActions('terms', { _hide: 'hide' }),

    hide () {
      if (this.isLock) {
        return
      }
      this._hide()
    },

    beforeEnter () {
      this.isLock = true
    },

    enter (el, done) {
      this.content = el.querySelector('.modal-content')
      this.enterTl = gsap.timeline({
        onComplete: () => {
          done()
        }
      })

      this.enterTl
        .fromTo(el, {
          opacity: 0
        }, {
          opacity: 1,
          duration: this.duration,
          ease: 'quad.out'
        })
    },

    afterEnter () {
      this.isLock = false
    },

    beforeLeave () {
      this.isLock = true
    },

    leave (el, done) {
      this.leaveTl = gsap.timeline({
        onComplete: () => {
          done()
        }
      })

      this.leaveTl
        .fromTo(el, {
          opacity: 1
        }, {
          opacity: 0,
          duration: this.duration,
          ease: 'quad.out'
        })
    },

    afterLeave () {
      this.isLock = false
      this.enterTl = null
      this.leaveTl = null
    },

    refreshIScroll () {
      if (!this.$iscroll) {
        return
      }

      setTimeout(() => this.$iscroll.refresh(), 0)
    },

    resetIScroll () {
      this.$iscroll.scrollTo(0, 0, 0);
    },

    handleMouseDown (ev) {
      if (ev.button !== 1) {
        return
      }
      ev.preventDefault()
      ev.stopPropagation()
    },

    handleResize () {
      this.refreshIScroll()
    }
  }
}
</script>

<i18n lang="yaml" src="@/locales/agreement.yaml"></i18n>

<style lang="scss" scoped>
.terms-modal {
  background: rgba(#000, 0.9);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: map-get($z-index, modal);
  backface-visibility: hidden;
}
  .modal-scroller {
    touch-action: none;
    position: relative;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 100%;
    height: 100%;

    > div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
    }
  }

  .modal-inner {
    position: relative;
  }

  .modal-closer {
    display: block;// for android4.4.x
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 308px;
    height: 132px;
    z-index: 500;
  }

  .modal-content {
    width: $base-w;
    margin-left: auto;
    margin-right: auto;
    padding: 40px 30px 160px;

    > .terms-title {
      $this: #{&};
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      height: 88px;
      margin-bottom: 55px;
      margin-left: auto;
      margin-right: auto;
      padding-left: 20px;
      padding-right: 20px;
      background-color: rgba(38, 34, 19, 0.75);
      $per1: 94/690*100%;
      $per2: (690-94)/690*100%;
      mask-image: linear-gradient(
        90deg,
        rgba(38,34,19,0) 0%,
        rgba(38,34,19,1) $per1,
        rgba(38,34,19,1) 50%,
        rgba(38,34,19,1) $per2,
        rgba(38,34,19,0) 100%
      );
      mask-repeat: repeat-y;
      color: $color-sub;
      border-top: 4px solid rgba(255, 255, 224, .8);
      border-bottom: 4px solid rgba(255, 255, 224, .8);
      font-size: $fs-middle;
      letter-spacing: 0.075em;
      text-shadow: 0 2px 2px rgba(#000, 0.6);

      @at-root {
        .-ja-jp {
          #{$this} {
            font-size: 2.7rem;
          }
        }
        .-fr-ca {
          #{$this} {
            padding-left: 47px;
            padding-right: 47px;
          }
        }
      }
    }

    > .terms-subtitle {
      color: $color-sub;
      font-size: $fs-middle;
      text-align: center;
      margin-bottom: 1em;
    }

    > .terms-text {
      color: $color-sub;
      font-size: $fs-middle;
      line-height: lh(46, $fs-middle);
      word-wrap: break-word;

      /deep/ a {
        text-decoration: underline;
        word-wrap: break-word;
      }
    }
  }
</style>