<template>
  <router-link
    v-if="tag === 'router-link'"
    :to="to"
    :class="classes"
    @click.native="handleClick"
    class="button"
  >
    <span
      v-if="text"
      v-html="text"
      class="inner"
    />
    <span class="inner" v-else>
      <slot />
    </span>
  </router-link>
  <component
    v-else
    :is="tag"
    :class="classes"
    @click="handleClick"
    class="button"
  >
    <span
      v-if="text"
      v-html="text"
      class="inner"
    />
    <span class="inner" v-else>
      <slot />
    </span>
  </component>
</template>

<script>
import ButtonMixin from '@/mixins/Button'

export default {
  props: {
    type: {
      type: String,
      default: 'button'
    },
    buttonColor: {
      type: String,
      default: 'blue',
    },
    text: {
      type: String,
      default: null
    },
    tag: {
      type: String,
      default: 'button'
    },
    to: {
      type: [Object, String]
    },
    forceGpu: {
      type: Boolean,
      default: false
    }
  },

  mixins: [ ButtonMixin ],

  computed: {
    classes () {
      return {
        '-blue': this.buttonColor === 'blue',
        '-gold': this.buttonColor === 'gold',
        '-gpu': this.forceGpu
      }
    }
  },

  methods: {
    handleClick () {
      this.animateButton()
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  width: 100%;
  height: 132px;
  font-size: $fs-base * 1.8;
  color: $color-sub;
  line-height: 1.1;
  text-shadow: $ts-base;
  display: block;
  text-align: center;
  word-wrap: break-word;
  padding-top: 4px;
  padding-bottom: 10px;

  $self: #{&};
  
  @at-root {
    .-pc {
      #{$self} {
        @include tap-event();
      }
    }
  }

  > .inner {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  &.-gpu {
    backface-visibility: hidden;
  }

  &.-disable {
    pointer-events: none;

    > .inner > img {
      opacity: 0.25;
    }
  }

  &.-blue {
    border-style: solid;
    border-width: 0px 76px;
    border-image: url(#{$img-path}button/blue/bg_btn.png) 0 76 fill stretch;
  }
  &.-gold {
    border-style: solid;
    border-width: 0px 76px;
    border-image: url(#{$img-path}button/gold/bg_btn.png) 0 76 fill stretch;
  }
}
</style>