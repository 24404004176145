export default class Theme {
  constructor(json) {
    try {
      this.map(json)
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
    }
  }

  map(json) {
    let status = null
    if (json.in_progress) {
      status = 1
    }
    if (json.not_started) {
      status = 2
    }
    if (json.finished) {
      status = 3
    }
    this.id = typeof json.id == 'number' ? json.id : null
    this.name = json.theme || null
    this.type = json.type || null
    this.status = status
  }

  toJSON() {
    return {
      model_id: this.id,
      model_name: this.name,
      model_type: this.type,
      model_status: this.status,
    }
  }
}
