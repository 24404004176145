import Enum from 'enum'

export const locales = new Enum({
  'DE_DE': {
    name: 'de-de',
    alias: 'de-DE',
    label: 'Deutsch',
    values: ['de-de', 'eude', 'de'],
  },
  'EN_GB': {
    name: 'en-gb',
    alias: 'en-GB',
    label: 'English (Europe/Australia)',
    values: ['en-gb', 'euen'],
  },
  'EN_US': {
    name: 'en-us',
    alias: 'en-US',
    label: 'English (US)',
    values:['en-us', 'usen', 'en'],
  },
  'ES_ES': {
    name: 'es-es',
    alias: 'es-ES',
    label: 'Español (Europa)',
    values: ['es-es', 'eues', 'es'],
  },
  'ES_MX': {
    name: 'es-mx',
    alias: 'es-MX',
    label: 'Español (Latinoamérica)',
    values: ['es-mx', 'uses'],
  },
  'FR_CA': {
    name: 'fr-ca',
    alias: 'fr-CA',
    label: 'Français (Canada)',
    values: ['fr-ca', 'usfr'],
  },
  'FR_FR': {
    name: 'fr-fr',
    alias: 'fr-FR',
    label: 'Français (Europe)',
    values: ['fr-fr', 'eufr', 'fr'],
  },
  'IT_IT': {
    name: 'it-it',
    alias: 'it-IT',
    label: 'Italiano',
    values: ['it-it', 'euit', 'it'],
  },
  'PT_BR': {
    name: 'pt-br',
    alias: 'pt-BR',
    label: 'Português (Brasil)',
    values: ['pt-br', 'uspt', 'pt'],
  },
  'JA_JP': {
    name: 'ja-jp',
    alias: 'ja-JP',
    label: '日本語',
    values: ['ja-jp', 'jpja', 'ja'],
  },
  'ZH_TW': {
    name: 'zh-tw',
    alias: 'zh-TW',
    label: '繁體中文',
    values: ['zh-tw', 'twzh', 'zh'],
  },
})

export function getLocale() {
  const language = (window.navigator.languages && window.navigator.languages[0]) ||
    window.navigator.language ||
    window.navigator.userLanguage ||
    window.navigator.browserLanguage
  const locale = locales.enums.find(item => item.value.values.includes(language))
  return locale ? locale : locales.JA_JP
}

export function getLocaleByName (name) {
  return locales.enums.find(item => item.value.name === name) 
}

export function validateLocale(lang) {
  return locales.enums.some(l => l.value.name === lang)
}
