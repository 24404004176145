import ModalModel from '@models/modal'

export default {
  namespaced: true,

  state: {
    modal: null,
    isShow: false
  },

  getters: {
    isSet (state) {
      return !!state.modal
    },
    isShow (state) {
      return state.isShow
    }
  },

  mutations: {
    set (state, value) {
      if (value instanceof ModalModel) {
        state.modal = value

        // @NOTE modalのレンダリング待ち
        setTimeout(() => {
          state.isShow = true
        }, 0)
      } else {
        console.warn('model/modalを適用してください')
      }
    },

    hide (state) {
      state.isShow = false
    },

    reset (state) {
      state.modal = null
    }
  },

  actions: {
    show ({ commit }, modal) {
      commit('set', modal)
    },

    hide ({ commit }) {
      commit('hide')
    },

    reset ({ commit }) {
      commit('reset')
    }
  }
}
