 import ModelLogin from '@models/login'
import { getServerParam } from '@constants/login'

export default {
  namespaced: true,

  state: {
    isLogin: false,
    user: null,
    isVoted: [],
  },

  getters: {
    isLogin (state) {
      return state.isLogin
    },

    user (state) {
      return state.user
    },

    getVoted: (state) => (themeId) => {
      const res = state.isVoted.find(item => item.theme_id === themeId)
      return res ? res.is_voted : false
    },
  },

  mutations: {
    setLoginState (state, bool) {
      state.isLogin = bool
    },

    setUser (state, user) {
      state.user = user
    },

    setVoteState (state, array) {
      state.isVoted = array
    },

  },

  actions: {
    init ({ commit }) {
      const useDummyData = process.env.VUE_APP_USE_DUMMY ? process.env.VUE_APP_USE_DUMMY.includes('login') : false || false
      const config = getServerParam(useDummyData)
      const login = new ModelLogin(config)

      commit('setLoginState', login.isLogin)
      commit('setUser', login.user)
      commit('setVoteState', login.isVoted)
    },
  }
}
