import ModelUser from '@models/user'

export default class Login {
  constructor(json) {
    try {
      this.map(json)
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
    }
  }

  map (json) {
    this.user = new ModelUser(json.me) || null
    this.isLogin = json.is_login || false
    this.isVoted = json.vote_histories || []
  }

  toJSON () {
    return {
      model_user: this.user,
      model_isLogin: this.isLogin,
      model_isVoted: this.is_voted,
    }
  }
}
