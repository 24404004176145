import { getServerParam } from '@constants/result'
import ResultModel from '@models/result'

export default {
  namespaced: true,

  state: {
    result: [],
    isOpen: false,
  },

  getters: {
    getResult (state) {
      return state.result
    },
    isOpen (state) {
      return state.result.length > 0
    }
  },

  mutations: {
    setResult (state, result) {
      state.result = result.map(r => new ResultModel(r))
    }
  },

  actions: {
    init ({ commit }) {
      const useDummyData = process.env.VUE_APP_USE_DUMMY ? process.env.VUE_APP_USE_DUMMY.includes('result') : false || false
      let result = getServerParam(useDummyData)
      if (result && result.items) {
        commit('setResult', result.items)
      }
    },
  }

}