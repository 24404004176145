<template>
  <router-link
    v-if="tag === 'router-link'"
    :to="to"
    @click.native="handleClick"
    class="button"
  >
    <slot />
  </router-link>
  <component
    v-else
    :is="tag"
    @click="handleClick"
    class="button"
  >
    <slot />
  </component>
</template>

<script>
import ButtonMixin from '@/mixins/Button'

export default {
  props: {
    text: {
      type: String,
      default: null
    },
    tag: {
      type: String,
      default: 'button'
    },
    to: {
      type: [Object, String]
    }
  },

  mixins: [ ButtonMixin ],

  methods: {
    handleClick () {
      this.animateButton()
      this.$emit('click')
    }
  }
}
</script>
