/**
 * vote
 * 現在のテーマの投票状態を管理する
 */
export default {
  namespaced: true,

  state: {
    ids: [],
    number: 0,
    themeId: null,
    // isVoteEnd: false
  },

  getters: {
    getId (state) {
      const len = state.ids.length
      if (len <= 0) {
        return null
      }
      
      return state.ids[len - 1]
    },

    getNumber (state) {
      return state.number
    },

    getIds (state) {
      return state.ids
    },

    getThemeId (state) {
      return state.themeId
    },

    isLastVote (state) {
      return state.ids.length === (state.number - 1)
    },

    isVoteEnd (state) {
      return state.ids.length >= state.number
    }
  },

  mutations: {
    /**
     * 特定テーマに対して投票IDを記録する
     * テーマIDが変わった場合、今までの投票履歴をリセットする
     * @param {any} state 
     * @param {object}
     */
    set (state, { theme, id }) {
      if (state.themeId !== theme.id) {
        state.ids = []
      }
      this.setTheme(theme)
      this.setId(id)
    },

    setId (state, id) {
      if (state.ids.length >= state.number) {
        // eslint-disable-next-line
        console.error('不正な回数の投票', id)
        return
      }
      state.ids.push(id)
      // eslint-disable-next-line
      console.log('store/vote', id, state.ids)
    },

    setTheme (state, { id, type }) {
      state.themeId = id
      state.number = type === 'pair' ? 2 : 1
    },

    reset (state) {
      state.themeId = null
      state.number = 0
      state.ids = []
    }
  },

  actions: {
    init ({ commit }, theme) {
      if (!theme) {
        return
      }
      commit('setTheme', theme)
    }
  }
}