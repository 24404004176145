import Vue from 'vue'
import Vuex from 'vuex'
import modal from './modules/modal'
import terms from './modules/terms'
import theme from './modules/theme'
import bgm from './modules/bgm'
import loader from './modules/loader'
import result from './modules/result'
import login from './modules/login'
import vote from './modules/vote'
import time from './modules/time'
import wallpapers from './modules/wallpapers'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },

  mutations: {
  },

  actions: {
  },

  modules: {
    modal,
    terms,
    theme,
    bgm,
    loader,
    result,
    login,
    vote,
    time,
    wallpapers
  }
})
