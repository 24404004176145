import { getLocalJson } from './local/index.js'

export const getServerParam = (useDummyData) => {
  let config = {}
  const element = document.getElementById('config')
  if (element && element.tagName.toLowerCase() == 'script' && !useDummyData) {
    try {
      config = JSON.parse(element.innerHTML)
    } catch {
      throw { error: 'JSON parse error' }
    }
  } else {
    config = getLocalJson()
  }
  const _config = {
    is_login: config.is_login,
    me: config.me,
    vote_histories: config.vote_histories.items
  }
  // eslint-disable-next-line
  if (useDummyData)  console.log('Refered Local Data Login', _config)
  return _config
}